import PropTypes from 'prop-types';
import React, { Component } from 'react';
import VideoPlayer from 'react-video-js-player';
import { connect } from 'react-redux';
import { CLOUD_URL, EVENTS } from '../../cfg/constants';
import { stripExtension } from '../../lib';
import { eventService } from '../../rxjs/event';
import { setVideoFullscreen } from '../../store/player/actions';
import {
    selectFullscreen,
    selectVideoFullscreen,
} from '../../store/player/selectors';

class Video extends Component {
    static propTypes = {
        endHandler: PropTypes.func.isRequired,
        src: PropTypes.string.isRequired,
        subtitles: PropTypes.bool.isRequired,
        autoPlay: PropTypes.bool,
        progress: PropTypes.string,
        interactive: PropTypes.bool,
        fluid: PropTypes.bool,
    };

    static defaultProps = {
        autoPlay: true,
        progress: null,
        fluid: false,
        interactive: false,
    };

    state = {
        scrollTop: 0,
    };

    componentDidMount() {
        const { subtitles } = this.props;
    }

    componentDidUpdate(prevProps) {
        const { subtitles } = this.props;
        if (subtitles !== prevProps.subtitles) {
            this.setSubtitles();
        }
    }

    onPlayerReady(player) {
        const { fluid, autoPlay, updateVideoFullscreen } = this.props;
        // console.log('Player is ready: ', player, fullscreen);
        this.player = player;
        this.player.fluid(fluid);
        const controlBar = player.getChild('controlBar');
        controlBar.addChild('button').addClass('vjs-custom-fullscreen-control');
        const customFullScreenButton = player
            .getChild('controlBar')
            .getChild('button');

        customFullScreenButton.on(['tap', 'click'], () => {
            const { videoFullscreen } = this.props;
            updateVideoFullscreen(!videoFullscreen);
        });

        const fullscreenToggle = controlBar.getChild('fullscreenToggle');
        const scrollContainer = document.getElementById('scroll-container');
        fullscreenToggle.on(['tap', 'click'], () => {
            if (this.player.isFullscreen()) {
                const { scrollTop } = scrollContainer;
                console.log('scrollTop', scrollTop);

                this.setState({
                    scrollTop,
                });
            }
        });
        this.player.on('fullscreenchange', () => {
            if (!this.player.isFullscreen()) {
                const { scrollTop } = this.state;
                setTimeout(() => {
                    scrollContainer.scrollTop = scrollTop;
                }, 400);
            } else {
                const { scrollTop } = scrollContainer;
                console.log('scrollTop', scrollTop);
                this.setState({
                    scrollTop,
                });
            }
        });
        this.setSubtitles();

        if (autoPlay) {
            const promise = this.player.play();

            if (promise !== undefined) {
                promise
                    .then(_ => {
                        console.log('Autoplay started!');
                    })
                    .catch(error => {
                        console.log('Autoplay was prevented.', error);
                    });
            }
        }
    }

    onVideoPlay(duration) {
        // console.log('Video played at: ', duration);
        this.duckAudio(true);
    }

    onVideoPause(duration) {
        const { interactive } = this.props;
        if (this.player.scrubbing()) {
            return;
        }
        if (this.player.readyState === 4 || !interactive) {
            this.duckAudio(false);
        }
    }

    onVideoTimeUpdate(duration) {
        // console.log('Time updated: ', duration);
    }

    onVideoSeeking(duration) {
        // console.log('Video seeking: ', duration);
    }

    onVideoSeeked(from, to) {
        // console.log(`Video seeked from ${from} to ${to}`);
    }

    onVideoEnd() {
        const { updateVideoFullscreen, videoFullscreen } = this.props;
        if (this.player.isFullscreen()) {
            this.player.exitFullscreen();
        }
        if (videoFullscreen) {
            updateVideoFullscreen(false);
        }

        const { endHandler, progress } = this.props;
        if (!progress) {
            this.duckAudio(false);
        }

        setTimeout(endHandler, 500);
    }

    setSubtitles = () => {
        const { src, subtitles } = this.props;
        if (!subtitles) {
            return;
        }
        const vttFile = stripExtension(src, '.vtt');
        const file = `${CLOUD_URL}/captions/${vttFile}`;
        this.player.addRemoteTextTrack({
            kind: 'captions',
            label: 'English',
            srclang: 'en',
            src: file,
        });

        const tracks = this.player.textTracks();
        for (let i = 0; i < tracks.length; i++) {
            const track = tracks[i];

            // Find the English captions track and mark it as "showing".
            if (track.kind === 'captions' && track.language === 'en') {
                track.mode = 'showing';
            }
        }
    };

    scrollOnLoad = () => {
        eventService.sendEvent(EVENTS.SCROLL);
    };

    duckAudio = (duck = true) => {
        const type = duck ? EVENTS.BG_AUDIO.FADE_OUT : EVENTS.BG_AUDIO.FADE_IN;
        console.log('duckAudio', type);
        eventService.sendEvent(type);
    };

    render() {
        const { src, autoPlay, interactive } = this.props;
        const preload = interactive ? 'auto' : 'metadata';
        return (
            <>
                <VideoPlayer
                    controls
                    autoplay={autoPlay}
                    preload={preload}
                    src={`${CLOUD_URL}/videos/${src}`}
                    onReady={this.onPlayerReady.bind(this)}
                    onPlay={this.onVideoPlay.bind(this)}
                    onPause={this.onVideoPause.bind(this)}
                    onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                    onSeeking={this.onVideoSeeking.bind(this)}
                    onSeeked={this.onVideoSeeked.bind(this)}
                    onEnd={this.onVideoEnd.bind(this)}
                    onLoad={this.scrollOnLoad}
                />
            </>
        );
    }
}

const mapStateToProps = state => ({
    fullscreen: selectFullscreen(state),
    videoFullscreen: selectVideoFullscreen(state),
});

const mapDispatchToProps = dispatch => ({
    updateVideoFullscreen: payload => {
        dispatch(setVideoFullscreen(payload));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Video);
