/* eslint-disable no-param-reassign */

// Easing function
export const swing = p => (0.5 - Math.cos(p * Math.PI) / 2);

// Adjust DOM element volume
export const adjustVolume = (
    element,
    newVolume,
    {
        duration = 500,
        easing = swing,
        interval = 5,
    } = {},
) => {
    const originalVolume = element.volume;
    const delta = newVolume - originalVolume;

    if (!delta || !duration || !easing || !interval) {
        element.volume = newVolume;
        return Promise.resolve();
    }

    const ticks = Math.floor(duration / interval);
    let tick = 1;

    return new Promise(resolve => {
        const timer = setInterval(() => {
            element.volume = originalVolume + (
                easing(tick / ticks) * delta
            );
            tick += 1;

            if (tick === ticks) {
                clearInterval(timer);
                resolve();
            }
        }, interval);
    });
};
